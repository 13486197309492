import React, { FC, useMemo } from 'react';

import { EvPieChart, EvSpinner, FormatHelper, SORT_ORDER } from '@evinced-private/ui-common';

import ChartsHelper from 'src/helpers/ChartsHelper';
import { IssueSeverities } from 'src/interfaces/IssuesOverviewResponse';
import { PieSlice } from 'src/types/PieChartTypes';

import pieHelper from '../../helpers/PieHelper';
import SeverityService from '../../services/severities/SeverityService';
import EvChartTableLink from '../common/ev-chart-table-link/EvChartTableLink';
import ChartWrapper from '../data-displays/overview-charts/chart-wrapper/ChartWrapper';
import ChartsSection, {
	SectionTextBlock
} from '../data-displays/overview-charts/charts-section/ChartsSection';

import EvincedAdvantageBarV2 from './bars/EvincedAdvantageBarV2';

interface IIssuesChartsProps {
	totalIssues: number;
	severitiesData: IssueSeverities[];
	totalCriticalIssues: number;
	showEvincedAdvantageChart?: boolean;
	onTableLinkItemClick?: (stateKey: string, stateValue: string) => void;
}

const DEFAULT_CHART_HEIGHT = 340;
const DEFAULT_CHART_WIDTH = 501;
const LABEL_FIELD = 'label';
const PERCENT_FIELD = 'percent';
const SEVERITY = 'Severity';
const ISSUES_PERCENTAGE = 'Issues Percentage';
const ISSUE_TYPE = 'Issue type';
const TOTAL_ISSUES = 'Total issues';
const PAGES_COUNT_FIELD = 'pagesCount';
const IssuesCharts: FC<IIssuesChartsProps> = ({
	totalIssues,
	severitiesData,
	totalCriticalIssues,
	showEvincedAdvantageChart = true,
	onTableLinkItemClick
}) => {
	const orderedSeveritiesData = useMemo(() => {
		return severitiesData?.sort((a, b) =>
			SeverityService.sortBySeverity(a.severity, b.severity, SORT_ORDER.DESC)
		);
	}, [severitiesData]);

	const getSectionTextBlock = (): SectionTextBlock => {
		return {
			title: 'Distribution of issues by type and severity',
			rows: [
				{
					label: 'Total number of issues',
					value: FormatHelper.formatNumber(totalIssues)
				},
				{
					label: 'Critical issues',
					value: FormatHelper.formatNumber(totalCriticalIssues)
				}
			]
		};
	};

	const fieldFormatter = ({ key, value }): React.ReactNode => {
		return <EvChartTableLink onClick={() => onTableLinkItemClick(key, value)} value={value} />;
	};

	const renderSeveritiesDistributionPie = (): JSX.Element => {
		const severitiesDistributionPieData = pieHelper.getSeveritiesDistributionPieDataV2(
			orderedSeveritiesData,
			totalIssues
		);

		if (severitiesDistributionPieData.length === 0) {
			return null;
		}

		const chartTitle = 'Breakdown by issue severity';
		return (
			<ChartWrapper title={`${chartTitle} chart`}>
				<EvPieChart
					className="severities-distribution"
					data={severitiesDistributionPieData}
					title={chartTitle}
					withLegend={false}
					width={DEFAULT_CHART_WIDTH}
					height={DEFAULT_CHART_HEIGHT}
					withAnimation={false}
					isInteractive={false}
					customTooltip={(context: PieSlice) => {
						return ChartsHelper.renderCustomTooltip(context, severitiesDistributionPieData);
					}}
					headerTooltipProps={{
						tooltipText:
							'The graph indicates the distribution of issues by Severity. Each part of the graph represents the % of issues by their severity.',
						tooltipTitle: `What is ${chartTitle}`,
						tooltipPlacement: 'right'
					}}
					tableProps={{
						columnsPattern: [
							{
								name: SEVERITY,
								field: LABEL_FIELD,
								formatter: (cell: string) => fieldFormatter({ key: 'severity', value: cell })
							},
							{ name: ISSUES_PERCENTAGE, field: PERCENT_FIELD },
							{ name: TOTAL_ISSUES, field: PAGES_COUNT_FIELD }
						]
					}}
				/>
			</ChartWrapper>
		);
	};

	const renderIssuesDistributionPie = (): JSX.Element => {
		const issuesDistributionPieData = pieHelper.getIssuesDistributionPieDataV2(
			orderedSeveritiesData,
			totalIssues
		);

		if (issuesDistributionPieData.length === 0) {
			return null;
		}
		const chartTitle = 'Distribution of issue types';
		return (
			<ChartWrapper title={`${chartTitle} chart`} className="avoid-break">
				<EvPieChart
					className="issue-distribution"
					data={issuesDistributionPieData}
					title={chartTitle}
					withLegend={false}
					width={DEFAULT_CHART_WIDTH}
					height={DEFAULT_CHART_HEIGHT}
					withAnimation={false}
					isInteractive={false}
					customTooltip={(context: PieSlice) => {
						return ChartsHelper.renderCustomTooltip(context, issuesDistributionPieData, true);
					}}
					headerTooltipProps={{
						tooltipText:
							'The graph indicates the distribution of issues by type and severity. Each part of the graph represents the % of issues by their type (e.g. Accessible name). The colors represents the severity of the issue type.',
						tooltipTitle: `What is ${chartTitle}`,
						tooltipPlacement: 'right'
					}}
					tableProps={{
						columnsPattern: [
							{
								name: ISSUE_TYPE,
								field: LABEL_FIELD,
								formatter: (cell: string) => fieldFormatter({ key: 'type', value: cell })
							},
							{ name: ISSUES_PERCENTAGE, field: PERCENT_FIELD },
							{ name: TOTAL_ISSUES, field: PAGES_COUNT_FIELD }
						],
						defaultSortByField: 'label'
					}}
				/>
			</ChartWrapper>
		);
	};

	const renderCriticalIssuesPie = (): JSX.Element => {
		const criticalIssuesPieData =
			pieHelper.getCriticalIssuesDistributionPieDataV2(orderedSeveritiesData);

		if (criticalIssuesPieData.length === 0) {
			return null;
		}

		const chartTitle = 'Breakdown of critical issues by type';
		return (
			<ChartWrapper title={`${chartTitle} chart`}>
				<EvPieChart
					className="critical-issue-distribution"
					data={criticalIssuesPieData}
					title={chartTitle}
					withLegend={false}
					width={DEFAULT_CHART_WIDTH}
					height={DEFAULT_CHART_HEIGHT}
					withAnimation={false}
					isInteractive={false}
					customTooltip={(context: PieSlice) => {
						return ChartsHelper.renderCustomTooltip(context);
					}}
					headerTooltipProps={{
						tooltipText:
							'The graph focuses on the distribution of the critical issues by issue type. Each part of the graph represents the % of issues by their type (e.g. Accessible name).',
						tooltipTitle: `What is ${chartTitle}`,
						tooltipPlacement: 'right'
					}}
					tableProps={{
						columnsPattern: [
							{
								name: ISSUE_TYPE,
								field: LABEL_FIELD,
								formatter: (cell: string) => fieldFormatter({ key: 'type', value: cell })
							},
							{ name: ISSUES_PERCENTAGE, field: PERCENT_FIELD }
						],
						defaultSortByField: 'label'
					}}
				/>
			</ChartWrapper>
		);
	};

	const renderEvincedAdvantageBar = (): JSX.Element => {
		if (totalCriticalIssues === 0 || !showEvincedAdvantageChart) {
			return null;
		}

		return (
			<ChartWrapper
				title="Evinced advantage: auto detection of critical issues chart"
				className="avoid-break"
			>
				<EvincedAdvantageBarV2
					severitiesData={orderedSeveritiesData}
					totalCriticalIssues={totalCriticalIssues}
				/>
			</ChartWrapper>
		);
	};

	const sectionTextBlock = getSectionTextBlock();

	return (
		<ChartsSection sectionTextBlock={sectionTextBlock} skiplinkId="issues-chart">
			{severitiesData ? (
				<>
					<div className="charts-row">
						{renderSeveritiesDistributionPie()}
						{renderIssuesDistributionPie()}
					</div>
					<div className="charts-row">
						{renderCriticalIssuesPie()}
						{renderEvincedAdvantageBar()}
					</div>
				</>
			) : (
				<EvSpinner />
			)}
		</ChartsSection>
	);
};

export default IssuesCharts;

import { OptionType } from '@evinced-private/ui-common';

import isEqual from 'lodash/isEqual';
import { JiraFieldsMap } from 'src/components/jira-issue-creator/JiraIssueCreator';

type Option = {
	id?: string;
	label?: string;
	name?: string;
	value?: string;
	displayName?: string;
	accountId?: string;
};
/**
 based on https://evinced.atlassian.net/browse/EW-6689
 custom field "customfield_10001" (team) was not visible
 since this fix is for a specific customer it is looking for the
 exact field strucure in order to process it.
 other customers with field "customfield_10001" will not be affected by this fix.
 * */
const customFieldOptions = {
	customfield_10001: {
		required: false,
		schema: {
			type: 'team',
			custom: 'com.atlassian.jira.plugin.system.customfieldtypes:atlassian-team',
			customId: 10001,
			configuration: {
				'com.atlassian.jira.plugin.system.customfieldtypes:atlassian-team': true
			}
		},
		name: 'Team',
		key: 'customfield_10001',
		autoCompleteUrl:
			'https://api.atlassian.com/ex/jira/3d2a0862-52e5-4ae8-9607-8a543b03a5a2/gateway/api/v1/recommendations',
		hasDefaultValue: false,
		operations: ['set']
	}
};

const isProcessCustomField = (field?: JiraFieldsMap): boolean => {
	const customOptionField = customFieldOptions.customfield_10001;
	if (!customOptionField) {
		return false;
	}
	return isEqual(customOptionField, field);
};

export const adjustOptions = (options: Option[], field?: JiraFieldsMap): OptionType[] => {
	return options?.map((option) => {
		if (field?.key === 'reporter' || isProcessCustomField(field?.key)) {
			return {
				label: option.label,
				value: option.value,
				item: option
			};
		}
		if (option.displayName) {
			return {
				label: option.displayName,
				value: option.accountId,
				item: option
			};
		}
		return {
			label: option.label || option.name || option.value,
			value: option.id || option.label,
			item: option
		};
	});
};

import _ from 'lodash';

import { BarDatum, EvRaceBarDatum, PieDatum } from '@evinced-private/ui-common';

import { PieSlice } from 'src/types/PieChartTypes';

import { ComponentOverviewResponse, SeverityData } from '../types/OverviewTypes';

import MathHelper from './MathHelper';
import RoutesHelper from './RoutesHelper';

const LIMIT_RESULTS_BY = 10;
export const MAX_PERCENT_OF_ISSUES_TO_COVER = 70;

const getComponentsWithCriticalV2 = (
	componentsCountHash: ComponentOverviewResponse
): BarDatum[] => {
	return componentsCountHash.criticalComponents.map(({ component, issuesCount }) => ({
		id: component,
		count: issuesCount
	}));
};

const getTopTenComponents = (components): BarDatum[] => {
	const sorted = components.sort((a, b) => b.count - a.count);
	return sorted.splice(0, LIMIT_RESULTS_BY);
};

const getBarWidth = (size: number, totalCount: number): number => {
	return MathHelper.calcPercentage(size, totalCount, false);
};

const calcDataForComponentsRaceGraph = (
	topTenComponents,
	totalCriticalIssues,
	propertyId: string,
	scanId: string
): {
	barData: EvRaceBarDatum[];
	percentCovered: number;
} => {
	let percentCovered = 0;
	const barData: { chartData: EvRaceBarDatum; issueCount: number }[] = [];

	for (let i = 0; percentCovered < MAX_PERCENT_OF_ISSUES_TO_COVER; i++) {
		const component = topTenComponents[i];

		if (!component) {
			break;
		}

		const percentOfIssuesInComponent = MathHelper.calcPercentage(
			component.count,
			totalCriticalIssues,
			false
		);

		percentCovered += percentOfIssuesInComponent;

		if (percentCovered > 100) {
			break;
		}

		const url = RoutesHelper.getComponentDrilldownPath(propertyId, scanId, component.id);

		const item = {
			chartData: {
				id: component.id,
				percent: Math.round(percentOfIssuesInComponent),
				url
			},
			issueCount: component.count
		};

		barData.push(item);
	}

	const totalCovered = totalCriticalIssues * (percentCovered / 100);

	// adding data regarding the width that every item
	// should get based on its size inside the top-ten components
	barData.forEach((b) => {
		b.chartData.widthPercentageOnChart = getBarWidth(b.issueCount, totalCovered);
	});

	return {
		barData: barData.map((b) => b.chartData),
		percentCovered: _.sum(barData.map((b) => b.chartData.percent))
	};
};

const getEvincedAdvantage = (evincedCount: number, othersCount: number): string => {
	// can't devide by zero
	if (othersCount <= 0) {
		return '';
	}

	let advantage = evincedCount / othersCount;
	advantage = Math.floor(advantage);
	if (advantage <= 1) {
		return '';
	}

	return `x${advantage}`;
};

const IssueWithSeveritySeparator = '$$$';
const formatIssueWithSeverityId = (type: string, severity: string): string => {
	return `${type}${IssueWithSeveritySeparator}${severity}`;
};

export type IssueWithSeverity = {
	id: string;
	percent: number;
	type: string;
	severity: string;
	pagesCount: number;
};

const enrichIssueTypesWithPagesInfoV2 = (
	severityData: SeverityData,
	totalPages: number
): IssueWithSeverity[] => {
	return severityData.types.map((type) => {
		const percent = MathHelper.calcPercentage(type.pagesCount, totalPages);
		return {
			id: formatIssueWithSeverityId(type.type, severityData.severity),
			percent,
			type: type.type,
			severity: severityData.severity, // used for coloring
			pagesCount: type.pagesCount
		};
	});
};

const renderCustomTooltip = (
	context: PieSlice,
	barData: PieDatum[] = [],
	addSeverity = false
): string => {
	const { y, label, options } = context;
	const count = barData.find((bar) => bar.percent === y)?.pagesCount;
	const countExpression = count ? `(${count.toLocaleString()} total)` : '';
	const severity = addSeverity ? `(${options.severity})` : '';
	return `<div class="ev-chart-custom-tooltip">
					<div> ${label} ${severity}</div>
					<div><span class="bold-text">${y}%</span> ${countExpression}</div>
				</div>`;
};

interface TooltipContext {
	y: number | string;
	name: string;
	[key: string]: unknown; // For any additional properties
}

const renderColumnCustomTooltip = (
	context: { y: string; name: string },
	barData: BarDatum[]
): string => {
	const { y, name }: TooltipContext = context;
	// Find the matching bar data
	const matchingBar = barData.find((item) => item.label === name || item.percent === y);

	// Extract information
	const severity = matchingBar?.severity;
	const count = matchingBar?.pagesCount;
	const countExpression = count ? `(${count.toLocaleString()} total)` : '';

	// Construct tooltip content
	const nameDisplay = severity ? `${name} (${severity})` : name;

	return `<div class="ev-chart-custom-tooltip">
       <div>${nameDisplay}</div>
       <div><span class="bold-text">${y}%</span> ${countExpression}</div>
    </div>`;
};
export default {
	getEvincedAdvantage,
	getComponentsWithCriticalV2,
	calcDataForComponentsRaceGraph,
	getTopTenComponents,
	enrichIssueTypesWithPagesInfoV2,
	renderCustomTooltip,
	renderColumnCustomTooltip
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';

import {
	EvSection,
	EvSpinner,
	EvTableColumn,
	EvTableOptions,
	TABLE_SR_SUMMARY_IDS,
	TableSortOption,
	useSkipLinks
} from '@evinced-private/ui-common';

import ExportDropdownMenu from 'src/components/export-dropdown-menu/ExportDropdownMenu';
import { downloadIssuesDropdownOptions } from 'src/consts/DownloadIssuesConsts';
import { ExportType } from 'src/interfaces/ExportType';
import { SummaryItem } from 'src/interfaces/SummaryItem';
import { useCommonFiltersStore } from 'src/providers/commonFiltersProvider/CommonFiltersProvider';
import { useConfiguration } from 'src/providers/configurationProvider/ConfigurationProvider';
import { useNotifications } from 'src/providers/notificationsProvider/NotificationsConsumer';
import { IPropertyViewContext, useProperyViewContext } from 'src/providers/PropertyViewProvider';
import { useTablesStore } from 'src/providers/tablesProvider/TablesProvider';
import { getInitialFilterOptions } from 'src/services/DataFilterService';
import { SiteScannerUiToggles } from 'src/services/LocalTogglesService';
import scanService from 'src/services/scan/ScanService';
import { temporaryTableId } from 'src/stores/TablesStore';
import { FilterParams } from 'src/types/FilterParams';
import { PaginationParams } from 'src/types/PaginationParams';

import ApiErrorHelper from '../../../helpers/ApiErrorHelper';
import useLocationFilters from '../../../hooks/useLocationFilters';
import Logger from '../../../services/Logger';
import CsvExportButton from '../../common/ev-table/csv-export-button/CsvExportButton';
import SiteScannerTable from '../../common/site-scanner-table/SiteScannerTable';
import SummaryPane from '../../common/summary-pane/SummaryPane';
import ScanDataFilter from '../../data-filter/DataFilter';
import ScanDataFilterHelper, { IFilterOptions } from '../../data-filter/DataFilterHelper';
import GeneralError from '../../general-error/GeneralError';

import './BaseScanView.scss';

export type ScanTableDefinition = {
	columns: EvTableColumn[];
	options: EvTableOptions;
	totalCount?: number;
	totalPaginationableResults?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[];
};

type FiltersDefinition = { freeTextSearch: boolean; types: boolean; severities: boolean };

interface IBaseScanViewProps {
	scanId: string;
	screenId: string;
	summaryPaneItems: SummaryItem[];
	tableDefinition: ScanTableDefinition;
	filtersDefinition?: FiltersDefinition;
	defaultTableSort: TableSortOption;
	loadSummaryData: () => Promise<void>;
	loadTableItems: (
		scanId: string,
		paginationParams: PaginationParams,
		filtersParams: FilterParams
	) => Promise<void>;
	renderCsvExportButton?: boolean;
	renderActionButtons?: () => JSX.Element;
	viewId?: string;
	propertyId?: string;
	tableWithUrlSwitcher?: boolean;
	componentId?: string;
	issueType?: string;
	pageUrl?: string;
}

const BaseScanViewV2: FC<IBaseScanViewProps> = ({
	scanId,
	screenId,
	summaryPaneItems,
	tableDefinition,
	filtersDefinition = {
		freeTextSearch: true,
		types: true,
		severities: true
	},
	defaultTableSort,
	loadSummaryData,
	loadTableItems,
	renderCsvExportButton = false,
	viewId,
	propertyId,
	renderActionButtons,
	tableWithUrlSwitcher = false,
	componentId,
	issueType,
	pageUrl
}: IBaseScanViewProps) => {
	const [isLoading, setLoading] = useState(true);
	const [isTableLoading, setTableLoading] = useState(true);
	const [filterOptions, setFilterOptions] = useState<IFilterOptions>(null);
	const [error, setError] = useState(null);

	// getting url params
	const { type, severity, isTemporary } = useLocationFilters();

	const tableId = useMemo(() => {
		return isTemporary ? temporaryTableId : screenId;
	}, [isTemporary, screenId]);

	// store definitions
	const tablesStore = useTablesStore();
	const commonFiltersStore = useCommonFiltersStore();

	// store actions and state
	const tablePaginationState = tablesStore.getPagination(tableId);
	const tableSortState = tablesStore.getSortOrder(tableId);
	const { page, sizePerPage } = tablePaginationState;
	const { dataField, order } = tableSortState ? tableSortState[0] : defaultTableSort;
	const appliedFilters = commonFiltersStore.getFilterValue(tableId);

	const notificationsContext = useNotifications();
	const propertyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => propertyViewContext?.currentView?.id || null,
		[propertyViewContext]
	);

	const { getToggle } = useConfiguration();
	const enableExcelExport = getToggle(SiteScannerUiToggles.ENABLE_EXPORT_TO_EXCEL_REPORT);

	const onClickDownloadOption = async (exportType: ExportType): Promise<void> => {
		const downloadRes = await scanService.downloadFilteredIssues({
			scanId,
			currentViewId,
			exportType,
			appliedFilters,
			filterOptions,
			dataField,
			order
		});

		notificationsContext.toast({
			show: true,
			status: downloadRes.status,
			announcement: downloadRes.announcement
		});
	};

	const downloadDropdownOptions = enableExcelExport
		? downloadIssuesDropdownOptions(onClickDownloadOption)
		: [];

	const renderExportCsvButton = useCallback(
		() => (
			<CsvExportButton
				scanId={scanId}
				propertyId={propertyId}
				filtersParams={{ filterValues: appliedFilters, filterOptions }}
				sortParams={{ sortField: dataField, sortOrder: order }}
			/>
		),
		[appliedFilters, filterOptions, propertyId, scanId, dataField, order]
	);

	const updateFilters = useCallback(
		(filters: IFilterOptions): void => {
			commonFiltersStore.updateSelectedFilters(tableId, filters);
			// change page to 1 when applying a new filter
			tablesStore.updatePagination(tableId, { page: 1, sizePerPage });
		},
		[commonFiltersStore, tableId, tablesStore, sizePerPage]
	);

	// wrapper functions around parent callbacks to fetch data
	const loadTableData = useCallback(async (): Promise<void> => {
		setTableLoading(true);
		try {
			await loadTableItems(
				scanId,
				{ page, sizePerPage, sortField: dataField, sortOrder: order },
				{ filterValues: appliedFilters, filterOptions }
			);
		} catch (error) {
			const msg = `Error getting table items data for scan ${scanId}.`;
			Logger.error(`${msg} ${tableId}`, error);
			setError(`${msg} ${ApiErrorHelper.getErrorMessage(error) || ''}`);
		}
		setTableLoading(false);
	}, [
		appliedFilters,
		scanId,
		filterOptions,
		page,
		sizePerPage,
		dataField,
		order,
		loadTableItems,
		tableId
	]);

	const loadFiltersInitialState = useCallback(async (): Promise<void> => {
		const scanFilterOptions = await getInitialFilterOptions(scanId, viewId, {
			component: componentId,
			issueType,
			issueUrl: pageUrl
		});
		setFilterOptions(scanFilterOptions);
		// in case there are already filters (from the store) no need to re-update them.
		// otherwise, if this is a temporary view - override the temporary filter in store.
		if (!appliedFilters || isTemporary) {
			updateFilters(
				ScanDataFilterHelper.getAppliedFiltersInitialValue(
					scanFilterOptions,
					type,
					severity,
					filtersDefinition.freeTextSearch,
					filtersDefinition.severities,
					filtersDefinition.types
				)
			);
		}
	}, [
		scanId,
		viewId,
		updateFilters,
		appliedFilters,
		type,
		severity,
		isTemporary,
		filtersDefinition.freeTextSearch,
		filtersDefinition.severities,
		filtersDefinition.types,
		componentId,
		issueType,
		pageUrl
	]);

	const initialLoad = useCallback(async (): Promise<void> => {
		setLoading(true);
		try {
			await Promise.all([loadSummaryData(), loadFiltersInitialState()]);
		} catch (error) {
			const msg = `Error getting filters and summary data for scan ${scanId}.`;
			Logger.error(msg, error);
			setError(`${msg} ${ApiErrorHelper.getErrorMessage(error) || ''}`);
		} finally {
			setLoading(false);
		}
	}, [scanId, loadFiltersInitialState, loadSummaryData]);

	useEffect(() => {
		/**
		 * This case where filtersOptions is defined
		 * but appliedFilter = undefined
		 * happens when changing to a different view for the 1st time
		 */
		if (!filterOptions || !appliedFilters) {
			initialLoad();
		}
	}, [initialLoad, filterOptions, appliedFilters]);

	useEffect(() => {
		if (appliedFilters && filterOptions) {
			loadTableData();
		}
	}, [appliedFilters, filterOptions, loadTableData]);

	// updating skip links data when page is loaded
	useSkipLinks(!isLoading);

	const renderExportElement = (): React.JSX.Element | null => {
		if (renderCsvExportButton) {
			return enableExcelExport ? (
				<ExportDropdownMenu
					className="base-scan-view-download-dropdown"
					dropdownId={`export-dropdown-${tableId}`}
					dropdownOptions={downloadDropdownOptions}
				/>
			) : (
				renderExportCsvButton()
			);
		}

		return null;
	};

	if (isLoading) {
		return (
			<div className="base-scan-view">
				<EvSpinner />
			</div>
		);
	}

	if (error) {
		return (
			<div className="base-scan-view">
				<GeneralError title="Error loading scan">{error}</GeneralError>
			</div>
		);
	}

	const summeryPaneActionButtonsProps = renderActionButtons ? { renderActionButtons } : {};

	return (
		<div className="base-scan-view">
			<SummaryPane
				items={summaryPaneItems}
				className="base-summary-pane"
				sectionLabel="Scan summary"
				{...summeryPaneActionButtonsProps}
			/>
			{!tableDefinition ? <EvSpinner /> : null}
			{tableDefinition && (
				<EvSection
					ariaLabel={`${tableDefinition.options.title} Table`}
					ariaDescribedby={TABLE_SR_SUMMARY_IDS}
					ariaLive="polite"
					skipLinkId="main-section"
				>
					<div className="filterRaw">
						{filterOptions && appliedFilters && (
							<ScanDataFilter
								filterOptions={filterOptions}
								filterValues={appliedFilters}
								onApply={updateFilters}
								textSearchEnabled={filtersDefinition.freeTextSearch}
								severitiesFilterEnabled={filtersDefinition.severities}
								typesFilterEnabled={filtersDefinition.types}
								hideLabel={true}
								disableButtons={isTableLoading}
							/>
						)}
						<div className="export-button">{renderExportElement()}</div>
					</div>
					<SiteScannerTable
						tableId={tableId}
						tableData={tableDefinition.data}
						isTableLoading={isTableLoading}
						// todo: when moving to entirely remote table - use only totalSize here
						totalCount={tableDefinition.totalCount || 0}
						totalPaginationableResults={tableDefinition.totalPaginationableResults}
						columns={tableDefinition.columns}
						options={tableDefinition.options}
						className="base-scan-analysis-table"
						withUrlSwitcher={tableWithUrlSwitcher}
					/>
				</EvSection>
			)}
		</div>
	);
};

export default observer(BaseScanViewV2);

import React, { FC, useCallback, useEffect, useState } from 'react';

import { EvincedColors, EvSpinner, EvStackedBarChart } from '@evinced-private/ui-common';

import { ITrend } from 'src/interfaces/Trend';

import SeverityTrendsHelper from '../../../helpers/SeverityTrendsHelper';
import { SEVERITIES_CHART_TYPE, SeverityTrendsDatum } from '../severity-trends/SeverityTrendTypes';

import './SeverityTrendChart.scss';

interface ISeverityTrendChart {
	scanTrendsData: ITrend[];
	chartType: SEVERITIES_CHART_TYPE;
	onBarItemClick: (scanId: string, severity: string) => void;
}

const patterns = EvincedColors.severitiesPatterns;

const SeverityTrendChart: FC<ISeverityTrendChart> = ({
	scanTrendsData,
	chartType,
	onBarItemClick
}) => {
	const [chartData, setChartData] = useState<SeverityTrendsDatum[]>(null);

	useEffect(() => {
		setChartData(SeverityTrendsHelper.getChartDataByType(scanTrendsData, chartType));
	}, [chartType, scanTrendsData]);

	const getTooltipValue = (value: number): string | number => {
		if (chartType === SEVERITIES_CHART_TYPE.TOTAL_ISSUES) {
			return value;
		}
		if (chartType === SEVERITIES_CHART_TYPE.AVERAGE_PER_PAGE) {
			return SeverityTrendsHelper.keepOneDecimal(value);
		}
		return `${value}`;
	};

	const onBarClickNew = useCallback(
		(barData): void => {
			onBarItemClick(barData.scanId, barData.label);
		},
		[onBarItemClick]
	);

	const renderCustomTooltip = (
		value: number,
		name: string,
		className = 'ev-bar-tooltip'
	): string => {
		const suffix = chartType === SEVERITIES_CHART_TYPE.PERCENTAGE ? '%' : '';
		return `<div class="${className}">
        <div>${name}</div>
        <div>${getTooltipValue(value)} ${suffix}</div>
    </div>`;
	};

	if (!chartData) {
		return <EvSpinner />;
	}

	return (
		<EvStackedBarChart
			title="Breakdown by Severity"
			className="severity-trend-chart"
			data={chartData}
			patterns={patterns}
			yAxisTitle="Issues"
			height={400}
			onBarClick={onBarClickNew}
			withFiltering
			pointWidth={78}
			customTooltip={(context: { y: never; label: never }): string => {
				const { y, label } = context;
				return renderCustomTooltip(y, label);
			}}
			customYaxisLabels={(value): string =>
				`${chartType === SEVERITIES_CHART_TYPE.PERCENTAGE ? `${value}%` : value}`
			}
		/>
	);
};

export default SeverityTrendChart;

import React, { FC, useMemo } from 'react';

import { BarDatum, EvSimpleBarChart, PatternData } from '@evinced-private/ui-common';

import { ERROR_TYPE } from 'src/consts/errors';
import { EVINCED_SEVERITIES_BY_ID } from 'src/consts/severityIDs';
import ChartsHelper from 'src/helpers/ChartsHelper';
import { SeverityData } from 'src/types/OverviewTypes';

import ColorsHelper from '../../../helpers/charts/ColorsHelper';
import IdFormatterHelper from '../../../helpers/IdFormatterHelper';
import MathHelper from '../../../helpers/MathHelper';
import EvChartTableLink from '../../common/ev-chart-table-link/EvChartTableLink';
import { ChartErrorView } from '../ChartErrorView';

interface IPagesBySeverityBarProps {
	onBarItemClick: (stateKey: string, stateValue: string | number) => void;
	pagesScanned: number;
	chartHeight?: number;
	chartWidth?: number;
	fitWidthToContent?: boolean;
	pagesSeverities?: SeverityData[];
}

const COLUMN_WIDTH_IN_PIXELS = 45;
const PADDING_BETWEEN_COLUMNS_IN_PIXELS = 30;
const DEFAULT_CHART_HEIGHT = 410;
const DEFAULT_CHART_WIDTH = 501;
const TOTAL_PAGES = 'Total pages';
const PAGES_PERCENTAGE = 'Pages percentage';
const PAGES_COUNT_FIELD = 'pagesCount';

const PagesBySeverityBar: FC<IPagesBySeverityBarProps> = ({
	pagesSeverities,
	pagesScanned,
	chartHeight = DEFAULT_CHART_HEIGHT,
	fitWidthToContent = false,
	chartWidth = DEFAULT_CHART_WIDTH,
	onBarItemClick
}) => {
	const barData = useMemo((): BarDatum[] => {
		return pagesSeverities?.map(({ pagesCount, severity }) => {
			const percentOfPagesWithSeverity = MathHelper.calcPercentage(pagesCount, pagesScanned);
			return {
				id: IdFormatterHelper.formatTextToId(severity),
				label: severity,
				percent: percentOfPagesWithSeverity,
				pagesCount
			};
		});
	}, [pagesSeverities, pagesScanned]);

	const patterns = useMemo(() => {
		return barData.map(
			({ id, label }) =>
				({
					id,
					label,
					color: ColorsHelper.newSeverityColors[EVINCED_SEVERITIES_BY_ID[id]]
				}) as PatternData
		);
	}, [barData]);

	const calculateChartWidth = (barData: BarDatum[]): number => {
		const numberOfCols = barData.length;
		const totalWidthOfCols = numberOfCols * COLUMN_WIDTH_IN_PIXELS;
		// the chart has padding after each column + padding before the first (columns + 1)
		const totalWidthWithoutCols = (numberOfCols + 1) * PADDING_BETWEEN_COLUMNS_IN_PIXELS;
		const widthOfAxisTicks = 50;
		return totalWidthOfCols + totalWidthWithoutCols + widthOfAxisTicks;
	};

	const width = useMemo(() => {
		if (!fitWidthToContent) {
			return chartWidth;
		}
		return calculateChartWidth(barData);
	}, [fitWidthToContent, barData, chartWidth]);

	const srSummaryData = useMemo(() => {
		return barData.map(
			(bar) =>
				({
					id: bar.id,
					label: bar.label,
					count: `${bar.percent}%`
				}) as PatternData
		);
	}, [barData]);

	const severityFieldFormatter = (cell: string): React.ReactNode => {
		return <EvChartTableLink onClick={() => onBarItemClick('severity', cell)} value={cell} />;
	};

	const errorType: ERROR_TYPE | null = useMemo(() => {
		if (!pagesSeverities || !pagesSeverities.length) {
			return ERROR_TYPE.FAILED;
		}
		if (barData.length === 0) {
			return ERROR_TYPE.NO_DATA;
		}
		return null;
	}, [pagesSeverities, barData]);

	const chartTitle = 'Pages impacted by issue severity';

	return (
		<EvSimpleBarChart
			title={chartTitle}
			className="pages-by-severity"
			width={!errorType && width}
			height={!errorType && chartHeight}
			data={barData}
			patterns={patterns}
			srSummaryData={srSummaryData}
			xAxisLabelsStyleWidthPercent="70%"
			dataValueFieldName="percent"
			onBarClick={(barData) => {
				// eslint-disable-next-line dot-notation
				onBarItemClick('severity', barData?.['key']);
			}}
			customTooltip={(context: { y: string; name: string }): string => {
				return ChartsHelper.renderColumnCustomTooltip(context, barData);
			}}
			customYaxisLabels={(value): string => `${value}%`}
			isFocusable
			withLegend={false}
			withYAxis
			tableProps={{
				dataType: 'Severity',
				columnsPattern: [
					{ name: 'Severity', field: 'label', formatter: severityFieldFormatter },
					{ name: PAGES_PERCENTAGE, field: 'percent' },
					{ name: TOTAL_PAGES, field: PAGES_COUNT_FIELD }
				]
			}}
			headerTooltipProps={{
				tooltipText:
					'The graph indicates the distribution of issues across pages. Each vertical graph represents the % of impacted (has issues) pages by issue severity. For example in this graph, more than 50% of the pages contains critical issues.',
				tooltipTitle: `What is ${chartTitle} severity`,
				tooltipPlacement: 'right'
			}}
			isError={!!errorType}
			renderErrorState={() => <ChartErrorView errorType={errorType} />}
		/>
	);
};

export default PagesBySeverityBar;

import React, { FC, useCallback, useEffect, useState } from 'react';

import { EvLoader, EvPieChart, PieDatum } from '@evinced-private/ui-common';

import { useDashboard } from 'src/providers/dashboard/DashboardProvider';
import { PieSlice } from 'src/types/PieChartTypes';

import ErrorLoadPageIcon from '../../../../components/icons/ErrorLoadPageIcon.svg';
import ChartsHelper from '../../../../helpers/ChartsHelper';
import DashboardHelper from '../../../../helpers/dashboard/DashboardHelper';
import LoadErrorMsgLayout from '../../../property/LoadErrorMsgLayout';

import './ScoreAcrossProperties.scss';

const DEFAULT_CHART_WIDTH = 420;
const LABEL_FIELD = 'label';
const SCORE_FIELD = 'score';
const PERCENT_FIELD = 'percent';
const PERFORMANCE = 'Performance';
const SCORE = 'Score';
const PROPERTY_PERCENTAGE = 'Percentage';
const TOOLTIP_TEXT =
	'A summary of all properties within the account according to their current score.';
const CHART_TITLE = 'Property score summary';
const errorTitle = 'Failed to load graph';
const errorMsg = 'Please try refreshing the page.';

const ScoreAcrossProperties: FC = () => {
	const [chartData, setChartData] = useState<PieDatum[]>([]);
	const { activePropertiesList } = useDashboard();

	const renderCustomLegend = useCallback((point: { label: string; score: number }) => {
		return `
		<div class="">
			<div class="legend-container">
				<span class="legend-item">${point.label} ${point.score}</span>
			</div>
		</div>
		`;
	}, []);

	useEffect(() => {
		if (activePropertiesList?.length) {
			setChartData(DashboardHelper.getScoreChartData(activePropertiesList));
		}
	}, [activePropertiesList]);

	const renderErrorState = useCallback(() => {
		return (
			<div className="score-chart-error-wrapper">
				<LoadErrorMsgLayout
					mainTitle={errorTitle}
					secondaryMsg={errorMsg}
					icon={ErrorLoadPageIcon}
				/>
			</div>
		);
	}, []);

	if (!activePropertiesList) {
		return <EvLoader message="Loading..." />;
	}

	return (
		<div className="score-across-properties">
			<EvPieChart
				data={chartData}
				title={CHART_TITLE}
				className="ev-pie-chart-title"
				width={DEFAULT_CHART_WIDTH}
				height={370}
				withAnimation={false}
				isInteractive={false}
				headerTooltipProps={{
					tooltipText: TOOLTIP_TEXT,
					tooltipTitle: `What is ${CHART_TITLE}`,
					tooltipPlacement: 'right'
				}}
				tableProps={{
					columnsPattern: [
						{ name: PERFORMANCE, field: LABEL_FIELD, sort: false },
						{ name: SCORE, field: SCORE_FIELD, sort: false },
						{ name: PROPERTY_PERCENTAGE, field: PERCENT_FIELD, sort: false }
					]
				}}
				isError={!activePropertiesList?.length}
				renderErrorState={renderErrorState}
				pieMode="thin"
				withLegend={true}
				customLegend={renderCustomLegend}
				customTooltip={(context: PieSlice) => {
					return ChartsHelper.renderCustomTooltip(context);
				}}
			/>
		</div>
	);
};

export default ScoreAcrossProperties;

import React, { FC, useCallback, useMemo } from 'react';

import { BarDatum, EvSimpleBarChart, PatternData } from '@evinced-private/ui-common';

import { EVINCED_SEVERITIES_BY_LABEL } from 'src/consts/severityIDs';
import { SeverityData } from 'src/types/OverviewTypes';

import ColorsHelper from '../../../helpers/charts/ColorsHelper';
import ChartsHelper, { IssueWithSeverity } from '../../../helpers/ChartsHelper';
import IdFormatterHelper from '../../../helpers/IdFormatterHelper';
import EvChartTableLink from '../../common/ev-chart-table-link/EvChartTableLink';

const MAX_ITEMS_PER_SEVERITY = 5;
const MIN_ITEMS_TO_DISPLAY = 5;
const MIN_PERCENT_FOR_DISPLAY_FALLBACK = 3;
const MIN_PERCENT_FOR_DISPLAY = 10;
const DEFAULT_CHART_HEIGHT = 450;
const DEFAULT_CHART_WIDTH = 501;
const TOTAL_ISSUES = 'Total issues';
const PAGES_COUNT_FIELD = 'pagesCount';

interface IPagesBySeverityBarProps {
	pagesSeverities?: SeverityData[];
	pagesScanned: number;
	onBarItemClick: (stateKey: string, stateValue: string | number) => void;
}

const PagesByIssueTypeBar: FC<IPagesBySeverityBarProps> = ({
	pagesSeverities,
	pagesScanned,
	onBarItemClick
}) => {
	const getBarData = useCallback(
		(minPercentForDisplay): BarDatum[] => {
			const barData: IssueWithSeverity[] = [];
			pagesSeverities.forEach((severityData) => {
				const enrichedIssueTypes = ChartsHelper.enrichIssueTypesWithPagesInfoV2(
					severityData,
					pagesScanned
				);
				const severityIssueTypes = enrichedIssueTypes.filter(
					(item) => item.severity === severityData.severity
				);
				const sorted = severityIssueTypes.sort((a, b) => b.percent - a.percent);
				const onlyBigIssues = sorted.filter(({ percent }) => percent > minPercentForDisplay);
				const limited = onlyBigIssues.splice(0, MAX_ITEMS_PER_SEVERITY);
				barData.push(...limited);
			});
			return barData.map((bar) => ({
				id: IdFormatterHelper.formatTextToId(bar.id),
				label: bar.type,
				percent: bar.percent,
				severity: bar.severity,
				pagesCount: bar.pagesCount
			}));
		},
		[pagesSeverities, pagesScanned]
	);
	const barData = useMemo(() => {
		let barData = getBarData(MIN_PERCENT_FOR_DISPLAY);
		// if barData is small, reduce the min percent for display
		if (barData.length <= MIN_ITEMS_TO_DISPLAY) {
			barData = getBarData(MIN_PERCENT_FOR_DISPLAY_FALLBACK);
		}
		return barData;
	}, [getBarData]);

	const patterns = useMemo(() => {
		return barData.map(
			(bar) =>
				({
					id: bar.id,
					label: bar.label,
					color: ColorsHelper.newSeverityColors[EVINCED_SEVERITIES_BY_LABEL[bar.severity]]
				}) as PatternData
		);
	}, [barData]);

	const srSummaryData = useMemo(() => {
		return barData.map(
			(bar) =>
				({
					id: bar.id,
					label: bar.label,
					count: `${bar.percent}%`
				}) as PatternData
		);
	}, [barData]);

	const typeFieldFormatter = (cell: string): React.ReactNode => {
		return <EvChartTableLink onClick={() => onBarItemClick('type', cell)} value={cell} />;
	};

	const chartTitle = 'Pages impacted per issue type';
	return (
		<div>
			<EvSimpleBarChart
				title={chartTitle}
				height={DEFAULT_CHART_HEIGHT}
				width={DEFAULT_CHART_WIDTH}
				data={barData}
				patterns={patterns}
				srSummaryData={srSummaryData}
				gridYValues={[0, 20, 40, 60, 80, 100]}
				gridYValuesTickInterval={20}
				pointWidth={38}
				className="pages-by-issue-type"
				dataValueFieldName="percent"
				isFocusable
				onBarClick={(point) => {
					const { name } = point;
					const severity = barData.find((item) => item.label === name)?.label;
					onBarItemClick('type', severity);
				}}
				customYaxisLabels={(value): string => `${value}%`}
				customTooltip={(context: { y: string; name: string }): string => {
					return ChartsHelper.renderColumnCustomTooltip(context, barData);
				}}
				withLegend={false}
				xAxisLabelsRotation={-45}
				tableProps={{
					dataType: 'Issue type',
					columnsPattern: [
						{ name: 'Issue type', field: 'label', formatter: typeFieldFormatter },
						{ name: 'Issues Percentage', field: 'percent' },
						{ name: TOTAL_ISSUES, field: PAGES_COUNT_FIELD }
					],
					defaultSortByField: 'label'
				}}
				headerTooltipProps={{
					tooltipText:
						'The graph indicates the distribution of issues across pages. Each vertical graph represents the % of impacted pages by different issue types. The colors represents the Severity of the issue type.',
					tooltipTitle: `What is ${chartTitle}`,
					tooltipPlacement: 'right'
				}}
			/>
		</div>
	);
};

export default PagesByIssueTypeBar;

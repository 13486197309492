import React, { FC } from 'react';

import { EvContactSupportEmailLink, EvIcon } from '@evinced-private/ui-common';

import NoDataIndication from '../common/ev-table/no-data-indication/NoDataIndication';
import NoTrends from '../icons/NoTrendsIcon.svg';

import './NoDataIndicationError.scss';

interface ITrendsNoDataIndicationProps {
	title?: string;
	renderTextMessage?: () => JSX.Element;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customIcon?: any;
}

const NoDataIndicationError: FC<ITrendsNoDataIndicationProps> = ({
	title = 'Oops! an error occurred',
	renderTextMessage,
	customIcon
}: ITrendsNoDataIndicationProps) => {
	const renderText = (): JSX.Element => {
		if (renderTextMessage) {
			return renderTextMessage();
		}
		return (
			<p>
				Please try again in a few minutes or contact&nbsp;
				<EvContactSupportEmailLink />
			</p>
		);
	};

	return (
		<NoDataIndication
			className="no-data-indication"
			title={title}
			renderIcon={() => (
				<EvIcon className="no-data-indication-icon" icon={customIcon || NoTrends} />
			)}
			renderTextMessage={() => renderText()}
		/>
	);
};

export default NoDataIndicationError;

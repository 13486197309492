import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import {
	BUTTON_TYPES,
	DropdownOptionType,
	EvAppsDropdownMenu,
	EvContactUsButton,
	EvDropdownMenu,
	EvIcon,
	EvTitle,
	IDropdownMenuOption,
	UserIcon
} from '@evinced-private/ui-common';

import { EXTERNAL_HUBSPOT_SOURCE } from '../../../consts/hubspot-consts';
import RoutesHelper from '../../../helpers/RoutesHelper';
import useUniversalLogin from '../../../hooks/useUniversalLogin';
import { ProductType } from '../../../interfaces/Tenant';
import { useUserTenant } from '../../../providers/userTenantProvider/UserTenantProvider';
import { hubspotAPIKey } from '../../../services/analytics/HubspotService';
import AuthenticationService from '../../../services/AuthenticationService';

import { getEvincedAppsDropdownMenuOptions } from './ev-header-helper';

import './EvHeader.scss';

type UserMenuOption = {
	id: string;
	title: string;
	type: DropdownOptionType;
	hasBottomBorder: boolean;
	disabled: boolean;
	buttonProps?: {
		title: string;
		type: BUTTON_TYPES;
		children: string | JSX.Element;
		onClick: () => void;
	};
	renderOption?: (accesibilityProps) => JSX.Element;
	url?: string;
	openInNewTab?: boolean;
};
interface IEvHeaderProps {
	isAuthenticatedPage: boolean;
	logoIcon: string;
	onLogoClickUrl: string;
	className?: string;
}

const EvHeader: FC<IEvHeaderProps> = ({
	isAuthenticatedPage,
	logoIcon,
	onLogoClickUrl,
	className,
	children
}) => {
	const [shouldShowInviteOption, setShouldShowInviteOption] = useState<boolean>(false);
	const [userEmail, setUserEmail] = useState('');
	const location = useLocation();
	const { isAuthenticated } = useUniversalLogin();
	const { tenant, user, hasAdminPermissions, getProductIdByType } = useUserTenant();

	const renderLogo = (): JSX.Element => {
		return (
			<Link className="ev-header-logo" to={onLogoClickUrl} aria-label="All properties">
				<EvIcon icon={logoIcon} />
			</Link>
		);
	};

	const getUserMenuOptions = (): UserMenuOption[] => {
		const options = [];
		if (isAuthenticated()) {
			options.push(
				{
					id: 'email',
					title: userEmail,
					buttonProps: {
						title: userEmail,
						type: BUTTON_TYPES.ICON,
						children: userEmail
					},
					hasBottomBorder: true,
					disabled: true,
					type: DropdownOptionType.BUTTON
				},
				{
					id: 'support',
					renderOption: (accessibilityProps): JSX.Element => {
						return (
							<EvContactUsButton
								type={BUTTON_TYPES.ICON}
								externalHubspotSource={EXTERNAL_HUBSPOT_SOURCE}
								formTitle="How can we help?"
								description="Please share what's on your mind and we will get back to you shortly."
								portalId={hubspotAPIKey}
								userEmail={userEmail}
								userTenant={tenant?.id}
								additionalInfo={{ isRequired: true, label: 'Message' }}
								accessibilityProps={accessibilityProps}
							/>
						);
					},
					hasBottomBorder: true,
					type: DropdownOptionType.CUSTOM
				}
			);
		}
		// render user invitation option only if the user has a tenant
		if (shouldShowInviteOption && hasAdminPermissions()) {
			options.splice(1, 0, {
				id: 'user-invitation',
				url: RoutesHelper.getUsersInvitationsHubPath(),
				title: 'Manage Users',
				type: DropdownOptionType.LINK,
				hasBottomBorder: true,
				openInNewTab: true
			});
		}
		options.push({
			id: 'logout',
			buttonProps: {
				title: 'Logout',
				type: BUTTON_TYPES.ICON,
				onClick: () => {
					AuthenticationService.logout();
				},
				children: 'Logout'
			},
			type: DropdownOptionType.BUTTON
		});

		return options;
	};

	const updateShouldShowInviteOption = useCallback((): void => {
		const isUserAuthenticated = isAuthenticated();
		if (!isAuthenticatedPage || !isUserAuthenticated) {
			setShouldShowInviteOption(false);
			return;
		}
		setShouldShowInviteOption(!!tenant);
	}, [isAuthenticated, isAuthenticatedPage, tenant]);

	useEffect(() => {
		setUserEmail(user?.email || '');
	}, [user]);

	useEffect(() => {
		if (shouldShowInviteOption) {
			return;
		}
		updateShouldShowInviteOption();
	}, [location, shouldShowInviteOption, updateShouldShowInviteOption]);

	const renderUserMenu = (): JSX.Element => {
		return (
			<div className="ev-user-menu">
				<EvDropdownMenu
					id="user-menu"
					triggerButtonProps={{
						type: BUTTON_TYPES.ICON,
						title: 'User Menu',
						children: <EvIcon icon={UserIcon} />
					}}
					options={getUserMenuOptions()}
				/>
			</div>
		);
	};

	const renderNavigationMenu = (): JSX.Element => {
		return <div className="ev-header-menu">{children}</div>;
	};

	const getEvincedAppsMenuOptions = (): IDropdownMenuOption[] => {
		if (isAuthenticated) {
			return getEvincedAppsDropdownMenuOptions({
				hasMfaLicense: !!getProductIdByType(ProductType.MOBILE_FLOW_ANALYZER),
				hasWebSdkLicense: !!getProductIdByType(ProductType.WEB_SDK),
				hasMsdkLicense: !!getProductIdByType(ProductType.MOBILE_SDK),
				hasSiteScannerLicense: !!getProductIdByType(ProductType.SCANNER),
				hasPlatformLicense: !!getProductIdByType(ProductType.WEB_FLOW_ANALYZER)
			});
		}

		return [];
	};

	const renderProductsDropdownMenu = (): JSX.Element => {
		if (AuthenticationService.isEvincedUser() && tenant?.products?.length > 1) {
			return <EvAppsDropdownMenu apps={getEvincedAppsMenuOptions()} />;
		}
		return null;
	};

	const headerTitleText = 'Site Scanner';
	return (
		<header
			className={classNames('ev-header', {
				[className]: !!className
			})}
		>
			<div className="header-content">
				{renderLogo()}
				<div className="header-content-products-title-wrapper">
					{renderProductsDropdownMenu()}
					<EvTitle className="header-title" titleText={headerTitleText} />
				</div>
				{renderNavigationMenu()}
				{isAuthenticatedPage && renderUserMenu()}
			</div>
		</header>
	);
};

export default EvHeader;

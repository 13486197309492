import React, { FC, useMemo } from 'react';

import pluralize from 'pluralize';

import { EvIcon, EvSpinner, EvStackedBarChart } from '@evinced-private/ui-common';

import ColorsHelper from '../../helpers/charts/ColorsHelper';
import { formatShortMonthDatetime } from '../../helpers/DateFormatHelper';
import NoDataIndication from '../common/ev-table/no-data-indication/NoDataIndication';
import NoTrendsIcon from '../icons/NoTrendsIcon.svg';

import './ScanComparisonIssueStatusBreakdown.scss';

const title = 'Breakdown by Issue Status';
const patterns = [
	{
		id: 'reoccurring',
		label: 'Reoccurring',
		color: ColorsHelper.reocurringColor
	},
	{
		id: 'resolved',
		label: 'Resolved',
		color: ColorsHelper.resolvedColor
	},
	{
		id: 'new',
		label: 'New',
		color: ColorsHelper.newIssuesColor
	}
];

export interface IIssueStatusBreakdown {
	newIssues: number;
	reoccurringIssues: number;
	resolvedIssues: number;
	baseDate: string;
	comparedDate: string;
}

type IIssueStatusBreakdownProps = IIssueStatusBreakdown & {
	isLoading: boolean;
};

const ScanComparisonIssueStatusBreakdown: FC<IIssueStatusBreakdownProps> = ({
	isLoading,
	newIssues = 0,
	reoccurringIssues = 0,
	resolvedIssues = 0,
	baseDate,
	comparedDate
}) => {
	const chartData = useMemo(
		() => [
			{
				date: formatShortMonthDatetime(baseDate),
				resolved: resolvedIssues,
				new: 0,
				reoccurring: reoccurringIssues,
				total: resolvedIssues + reoccurringIssues
			},
			{
				date: formatShortMonthDatetime(comparedDate),
				new: newIssues,
				resolved: 0,
				reoccurring: reoccurringIssues,
				total: newIssues + reoccurringIssues
			}
		],
		[baseDate, comparedDate, newIssues, reoccurringIssues, resolvedIssues]
	);

	const renderCustomTooltip = (value: number, name: string, pluralizeString: string): string => {
		return `<div class="ev-bar-tooltip">
        <div>${name} ${pluralize(pluralizeString, null, false)}</div>
        <div>${value}</div>
    </div>`;
	};

	if (isLoading) {
		return (
			<div className="compare-scans-issue-status-breakdown">
				<EvSpinner />
			</div>
		);
	}

	if (newIssues === 0 && reoccurringIssues === 0 && resolvedIssues === 0) {
		return (
			<div className="compare-scans-issue-status-breakdown">
				<NoDataIndication
					className="scan-no-data-indication"
					title="No Issues"
					renderIcon={() => <EvIcon className="no-data-indication-icon" icon={NoTrendsIcon} />}
					renderTextMessage={() => <span>There are no issues to show</span>}
				/>
			</div>
		);
	}

	const renderSRSummary = (): JSX.Element => {
		return (
			<>
				{title}
				On {chartData[0].date}: Resolved issues - {chartData[0].resolved}. On {chartData[1].date}:
				Reoccurring issues - {chartData[1].reoccurring}, New issues - {chartData[1].new}.
			</>
		);
	};

	return (
		<EvStackedBarChart
			title={title}
			className="compare-scans-issue-status-breakdown"
			customSrSummaryRenderer={renderSRSummary}
			data={chartData}
			patterns={patterns}
			yAxisTitle="Issues"
			height={410}
			withFiltering
			pointWidth={26}
			customTooltip={(context: { y: number; name: string }): string => {
				const { y, name } = context;
				return renderCustomTooltip(y, name, 'Issue');
			}}
		/>
	);
};

export default ScanComparisonIssueStatusBreakdown;
